import React, { forwardRef, useContext } from "react";
import {
  Button,
  TextField,
  Divider,
  Typography,
  Select,
  MenuItem,
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  Stack,
  DialogActions,
  FormControl,
  InputAdornment,
} from "@mui/material";
import { sharingAPI } from "../api/TimestampAPI";
import moment from "moment";
import SharedList from "../components/SharedList";
import CustomLabel from "../components/Input/CustomLabel";
import { useTranslation } from "react-i18next";
interface ShareVersionProps {
  open: boolean;
  onClose: () => void;
  version_object: any;
  current_shares: any[];
}

const ShareVersion = forwardRef<HTMLDivElement, ShareVersionProps>(
  ({ open, onClose, version_object, current_shares }, ref) => {
    const [email, setEmail] = React.useState("");
    const [validity, setValidity] = React.useState(28800);
    const [notes, setNotes] = React.useState("");
    const [notesCharCount, setNotesCharCount] = React.useState(0);

    const handleSubmit = async (event: React.FormEvent) => {
      event.preventDefault();
      await sharingAPI.shareFile(
        version_object.creation_id,
        version_object.version,
        {
          shared_email: email,
          date_expires: moment()
            .add(validity, "seconds")
            .format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ"),
          notes: notes,
        }
      );
      onClose();
    };

    const notesChangeHandler = (e: any) => {
      setNotes(e.target.value);
      setNotesCharCount(e.target.value.split("").length);
    };

    const { t } = useTranslation();
    return (
      <Dialog open={open} onClose={onClose} fullWidth={true}>
        <Box component="form" onSubmit={handleSubmit}>
          <DialogTitle>{t('share_creation_form.title', { creation_name: version_object?.name })}</DialogTitle>
          <DialogContent dividers>
            <Stack spacing={4}>
              <Typography variant="subtitle2">
                {t('share_creation_form.description')}
              </Typography>
              <Box>
                <TextField
                  autoFocus
                  id="email"
                  type="email"
                  fullWidth
                  value={email}
                  label={t('share_creation_form.fields.email_label')}
                  helperText={t('share_creation_form.fields.email_helpertext')}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Box>

              <Box>
                <TextField
                  select
                  id="share-duration"
                  value={validity}
                  label={t('share_creation_form.fields.duration_label')}
                  helperText={t('share_creation_form.fields.duration_helpertext')}
                  onChange={(e) => setValidity(parseInt(e.target.value as string))}
                  fullWidth
                >
                  <MenuItem value={1800}>30 {t('duration_units.minutes')}</MenuItem>
                  <MenuItem value={3600}>1 {t('duration_units.hour')}</MenuItem>
                  <MenuItem value={7200}>2 {t('duration_units.hours')}</MenuItem>
                  <MenuItem value={14400}>4 {t('duration_units.hours')}</MenuItem>
                  <MenuItem value={28800}>8 {t('duration_units.hours')}</MenuItem>
                  <MenuItem value={43200}>12 {t('duration_units.hours')}</MenuItem>
                  <MenuItem value={86400}>1 {t('duration_units.day')}</MenuItem>
                  <MenuItem value={259200}>3 {t('duration_units.days')}</MenuItem>
                  <MenuItem value={604800}>1 {t('duration_units.week')}</MenuItem>
                  <MenuItem value={1209600}>2 {t('duration_units.weeks')}</MenuItem>
                  <MenuItem value={2592000}>1 {t('duration_units.month')}</MenuItem>
                  <MenuItem value={7776000}>3 {t('duration_units.months')}</MenuItem>
                </TextField>
              </Box>

              <Box>
                <TextField
                  id="notes"
                  multiline
                  rows={4}
                  fullWidth
                  value={notes}
                  onChange={notesChangeHandler}
                  label={t('share_creation_form.fields.notes_label')}
                  helperText={t('share_creation_form.fields.notes_helpertext')}
                  inputProps={{ maxLength: 250 }}
                  InputProps={{
                    endAdornment: <InputAdornment variant="filled" position="end" sx={{}}>
                      <Typography fontSize="small" sx={{ color: "rgba(172, 172, 185, 1)" }}>
                        {`${notesCharCount}/250`}
                      </Typography>
                    </InputAdornment>
                  }}
                />
                {/* <Box sx={{ textAlign: "right" }}>
                  <Typography
                    sx={{ mt: 1, color: "rgba(172, 172, 185, 1)" }}
                  >{`${notesCharCount}/250`}</Typography>
                </Box> */}
              </Box>

              {current_shares.length > 0 ?
                (<><Divider />
                  <Typography variant="h6" component="div" gutterBottom>
                    {t('shared_with')}
                  </Typography>
                  <SharedList
                    shares={current_shares}
                    creationId={version_object.creation_id}
                    version={version_object.version}
                  />
                </>)
                : null}
            </Stack>

          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={onClose}>
              {t('cancel')}
            </Button>
            <Button type="submit" variant="contained">
              {t('share')}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    );
  }
);

export default ShareVersion;
