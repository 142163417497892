import { useState, useContext } from "react";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Typography,
  MenuList,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { styled } from "@mui/system";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { useTranslation } from "react-i18next";
import { stringAvatar } from "../Helper";
import LanguageSelect from "./LanguageSelect";
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import AboutDialog from "./AboutDialog";
import { TenantConfigContext } from "../context/tenantConfigContext";
import ProfileSelect from "./ProfileSelect";

const UserProfileButton = styled(Button)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: 10,
  borderRadius: "100px",
  cursor: "pointer",
  color: theme.palette.text.primary,
  textTransform: "capitalize",
  padding: 8,
  border: 0,
}));

const ProfileDropdown = () => {
  const { tokenData, logOut } = useContext<IAuthContext>(AuthContext);
  const tenantConfig = useContext(TenantConfigContext);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLElement> | React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));

  const [openInfo, setOpenInfo] = useState(false);

  const firstName = tokenData?.given_name || tokenData?.firstName;
  const lastName = tokenData?.family_name || tokenData?.lastName;
  const displayName = `${firstName} ${lastName}`;

  return (
    <>
      {tenantConfig?.show_about_dialog === true && <AboutDialog open={openInfo} onClose={() => setOpenInfo(false)} />}
      <UserProfileButton
        id="profile-button"
        aria-controls="profile-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar
          {...stringAvatar(displayName)}
          sx={{
            ...stringAvatar(displayName).sx,
            width: 32,
            height: 32,
            fontSize: 16,
          }}
        />
        {!isSmUp ? null :
          <Typography>
            {displayName}
          </Typography>
        }
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </UserProfileButton>
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "profile-button",
        }}
      >
        <MenuList dense sx={{ p: 0 }}>
          {tenantConfig?.show_about_dialog === true && (
            <MenuItem onClick={() => setOpenInfo(true)}>
              <ListItemIcon>
                <HelpOutlineRoundedIcon />
              </ListItemIcon>
              <ListItemText>{t("info")}</ListItemText>
            </MenuItem>
          )}
          <MenuItem onClick={() => logOut()}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText>{t("logout")}</ListItemText>
          </MenuItem>
          {!isSmUp &&
            <>
              <Divider />
              <Box sx={{ py: 1, px: 2 }}>
                <ProfileSelect />
              </Box>
            </>
          }
          <Divider />
          <LanguageSelect />
        </MenuList>
      </Menu>
    </>
  );
};
export default ProfileDropdown;
