import axios from "axios";
import { API_ENDPOINT } from "../Settings";
import api from "./base";

export async function getfile(versionId, mimeType, forDownload = false) {
  try {
    const response = await api.get(`/files/${versionId}/`, {
      params: {
        for_download: forDownload,
      },
      headers: {
        "Content-Type": mimeType,
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
}

export async function getSharedFile(sharing_id) {
  try {
    const response = await axios.get(`${API_ENDPOINT}/api/v1/sharing/${sharing_id}/`);
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
}

export async function trackSharedFileAccess(sharing_id) {
  try {
    const response = await axios.post(`${API_ENDPOINT}/api/v1/sharing/${sharing_id}/`,
      {}
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
}

export async function getShares(creation_id, versionNr, revoked = null) {
  try {
    let params = {};
    if (revoked !== null) {
      params = { revoked: revoked };
    }
    const response = await api.get(
      `/creations/${creation_id}/versions/${versionNr}/sharing/`,
      {
        params: params,
      }
    );

    return response.data.results;
  } catch (error) {
    console.error(error);
    return {};
  }
}

export async function shareFile(
  creation_id,
  versionNr,
  share_object = { shared_email: "", date_expires: "", notes: "" }
) {
  try {
    const response = await api.post(
      `/creations/${creation_id}/versions/${versionNr}/sharing/`,
      share_object
    );
    return response.data.results;
  } catch (error) {
    console.error(error);
    return {};
  }
}

export async function unshareFile(creation_id, versionNr, share_id) {
  try {
    const response = await api.delete(
      `/creations/${creation_id}/versions/${versionNr}/sharing/${share_id}/`,
    );
    return response.data.results;
  } catch (error) {
    console.error(error);
    return {};
  }
}

export async function hashExists(hash) {
  try {
    const response = await api.get(`/check-hash/${hash}/`);
    return response.data;
  } catch (error) {
    if (error.response.status === 404) {
      return false;
    }

    console.error(error);
    return {};
  }
}