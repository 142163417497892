import { Avatar, Box } from "@mui/material";
import { THEME } from "./Settings";
import { themes } from "./theme/themes";

const themeName = THEME
const theme = themes[themeName];

// Helper to generate colorful avatar
function stringToColor(string: string) {
  // return theme.avatarcolor if exists
  if (theme.avatarColor) {
    return theme.avatarColor;
  }

  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string) {
  // If name has no spaces, use first 2 characters
  let children;
  if (name.indexOf(" ") === -1) {
    children = name.substring(0, 2).toUpperCase();
  } else {
    children = `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`;
  }
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: children,
  };
}

export function isAdmin(accessToken: object) {
  if (!accessToken) {
    return false;
  }
  return accessToken["roles"]?.includes("admin") ?? false;
}

export const getUserAvatar = (user) => {
  const avatarProps = stringAvatar(
    `${user?.first_name} ${user?.last_name}`
  );
  return (
    <Avatar
      sx={{
        bgcolor: avatarProps.sx.bgcolor,
        fontSize: 12,
        width: 24,
        height: 24,
      }}
    >
      {avatarProps.children}
    </Avatar>
  );
};

export const getUserAvatarWithText = (user) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      {getUserAvatar(user)}
      {user?.first_name}{" "}
      {user?.last_name}
    </Box>
  )
}