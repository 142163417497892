import React, { useContext, useState } from 'react'
import { AuthContext, IAuthContext } from 'react-oauth2-code-pkce';
import { isAdmin } from '../../Helper';
import { Alert, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { statsAPI } from '../../api/TimestampAPI';
import { useTranslation } from 'react-i18next';
import StatSparklineCard from '../../components/Stats/StatSparklineCard';
import LineChartModal from '../../components/Stats/LineChartModal';
import ChartOptions from '../../components/Stats/ChartOptions';

const Stats = () => {
  const { tokenData } = useContext<IAuthContext>(AuthContext);
  const { t } = useTranslation()
  const [timeframe, setTimeframe] = useState("month")
  const [cumulative, setCumulative] = useState(false)
  const [selectedStat, setSelectedStat] = useState(null)
  const [chartModalOpen, setChartModalOpen] = useState(false)
  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ['stats'],
    queryFn: () => statsAPI.list(),
  })

  if (!isAdmin(tokenData)) {
    return (
      <Alert severity="error">Forbidden</Alert>
    )
  }

  if (isPending) return (<CircularProgress />)
  if (error) return (<div>{"An error has occurred: " + error.message}</div>)

  const handleTimeframe = (
    event: React.MouseEvent<HTMLElement>,
    newTimeframe: string,
  ) => {
    // Seems that when you click on the same button, it returns null
    if (newTimeframe === null) {
      return;
    }
    setTimeframe(newTimeframe);
  };

  const handleExpandChart = (stat) => {
    setSelectedStat(stat)
    setChartModalOpen(true)
  }

  const handleCloseChart = () => {
    setSelectedStat(null)
    setChartModalOpen(false)
  }

  const statNameLookup = {
    project_count: t('projects'),
    creation_count: t('creations'),
    creation_version_count: t('versions'),
    timestamp_count: t('timestamps'),
    file_count: t('files'),
    storage_usage: t('storage'),
    user_count: t('users'),
    creation_version_sharing_count: t('shared versions'),
    creation_version_sharing_link_count: t('shared links'),
  }

  return (
    <>
      <Typography
        variant="h5"
        sx={{
          fontWeight: "600",
          margin: "20px 0px",
          textTransform: "capitalize",
        }}
      >
        {t("dashboard")}
      </Typography>
      <Stack direction="column" gap={2}>
        <ChartOptions timeframe={timeframe} cumulative={cumulative} setTimeframe={setTimeframe} setCumulative={setCumulative} handleTimeframe={handleTimeframe} />
        <Grid container spacing={2}>
          {data.data.map((stat, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <StatSparklineCard stat={stat} timeframe={timeframe} cumulative={cumulative} statNameLookup={statNameLookup} handleExpandChart={handleExpandChart} />
            </Grid>
          ))}
        </Grid >
      </Stack>
      {selectedStat && <LineChartModal data={selectedStat} timeframe={timeframe} statNameLookup={statNameLookup} cumulative={cumulative} open={chartModalOpen} onClose={handleCloseChart} />}
    </>
  )
}

export default Stats
