import React, { useContext, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from "@mui/material/styles";
import { themes } from "../theme/themes";
import { THEME } from '../Settings';
import Sidebar from '../components/Sidebar';
import { Link, Stack } from '@mui/material';
import { TenantConfigContext } from '../context/tenantConfigContext';
import { useTranslation } from 'react-i18next';
import ProfileSelect from '../components/ProfileSelect';
import ProfileDropdown from '../components/ProfileDropdown';
import AboutDialog from '../components/AboutDialog';
import CreateUpdateProjectDialog from '../modals/CreateUpdateProjectDialog';
import { CRUD } from '../Constants';

const drawerWidth = 240;

const introductionOpen = () => {
    // check if the user has seen the introduction before and if not, open the introduction
    return localStorage.getItem('seenIntroduction') !== 'true'
}

export default function ResponsiveLayout() {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [openIntroduction, setOpenIntroduction] = useState(introductionOpen());
    const [openCreateProject, setCreateProject] = useState(false);
    const tenantConfig = useContext(TenantConfigContext);
    const { t } = useTranslation();
    const themeName = THEME
    const theme = themes[themeName]?.theme
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));


    // check if the document is in RTL mode and set an rtl variable to true
    const isRtl = document.dir === "rtl";

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawerContent = (
        <Box
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
        >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
                <Sidebar
                    setCreateProject={setCreateProject}
                />
            </Box>
        </Box>
    );

    return (
        <ThemeProvider theme={theme}>
            {tenantConfig?.show_about_dialog === true && <AboutDialog open={openIntroduction} onClose={() => setOpenIntroduction(false)} />}

            <CreateUpdateProjectDialog
                open={openCreateProject}
                onClose={() => setCreateProject(false)}
                action={CRUD.Create}
            />

            <Box
                sx={{
                    display: 'flex',
                }}
            >
                <CssBaseline />
                <AppBar
                    position="fixed"
                    sx={{
                        zIndex: theme.zIndex.drawer + 1,
                        backgroundColor: "background.paper",
                        boxShadow: "0px 4px 10px -1px rgba(227, 236, 251, 1)",
                        color: "rgba(92, 92, 111, 1)",
                        direction: isRtl ? "rtl" : "ltr",
                    }}
                >
                    <Toolbar>
                        {!isSmUp && (
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { sm: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                        {/* LOGO */}
                        <Stack direction={isRtl ? "row-reverse" : "row"} gap={"50px"} alignItems={"center"} sx={{ mr: 2 }}>

                            <a href={tenantConfig?.logo_url || '#'} style={{ display: "inline-flex", alignItems: "center" }}>
                                <img src={theme?.logo} style={{ maxHeight: "50px", maxWidth: "160px" }} alt="" />
                            </a>
                            {tenantConfig?.header_link && (
                                <Link sx={{ display: { xs: "none", md: "block" } }} href={tenantConfig?.header_link?.url} color={"inherit"} underline={"hover"}>{t('go_back_to')}{' '}{tenantConfig?.header_link?.title}</Link>
                            )}
                        </Stack>

                        {/* User Profile Dropdown */}
                        <Stack direction={isRtl ? "row-reverse" : "row"} spacing={2} alignItems={"center"} sx={{ marginLeft: isRtl ? 1 : 'auto', marginRight: isRtl ? 'auto' : 1 }}>
                            {isSmUp && <ProfileSelect />}
                            <ProfileDropdown />
                        </Stack>
                    </Toolbar>
                </AppBar>
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="sidebar"
                >
                    <Drawer
                        variant={isSmUp ? 'permanent' : 'temporary'}
                        open={isSmUp || mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        anchor={isRtl ? 'right' : 'left'}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth,
                                borderRight: "none",
                            },

                        }}
                    >
                        {drawerContent}
                    </Drawer>
                    {isSmUp && (
                        <Drawer
                            variant="permanent"
                            open
                            anchor={isRtl ? 'right' : 'left'}
                            sx={{
                                display: { xs: 'none', sm: 'block' },
                                '& .MuiDrawer-paper': {
                                    boxSizing: 'border-box',
                                    width: drawerWidth,
                                    borderRight: "none",
                                    overflow: 'hidden'
                                },
                            }}
                        >
                            {drawerContent}
                        </Drawer>
                    )}
                </Box>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        py: 1,
                        px: 2,
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        maxWidth: '100vw',
                    }}
                >
                    <Toolbar />
                    <Outlet />
                </Box>
            </Box>
        </ThemeProvider>
    );
}