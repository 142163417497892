import { TAuthConfig } from "react-oauth2-code-pkce"

const hostname = window.location.hostname;

const getSettings = async () => {

    const url = `${process.env.REACT_APP_ENV_CONFIG_URL}/${hostname}`
    try {
        const response = await fetch(url);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching remote config:", error);
        return {};
    }
}

// let settings;

const initializeSettings = async () => {
    let settings
    if (hostname === "localhost") {
        settings = {
            REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI,
            CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
            AUTH_ENDPOINT: process.env.REACT_APP_AUTH_URI,
            TOKEN_ENDPOINT: process.env.REACT_APP_TOKEN_URI,
            LOGOUT_ENDPOINT: process.env.REACT_APP_LOGOUT_URI,
            API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
            THEME: process.env.REACT_APP_THEMENAME
        }
    } else {
        settings = await getSettings()
    }
    return settings
}

const settings = await initializeSettings()

export const AUTH_CONFIG: TAuthConfig = {
    clientId: settings.CLIENT_ID,
    authorizationEndpoint: settings.AUTH_ENDPOINT,
    tokenEndpoint: settings.TOKEN_ENDPOINT,
    logoutEndpoint: settings.LOGOUT_ENDPOINT,
    redirectUri: settings.REDIRECT_URI || window.origin,
    scope: 'profile openid',
    // Keep autoLogin false. When True it has a bug that can make the 
    // app stuck as it does not redirect to login page. We have a workaround component
    // to redirect the user to login page if the user is not logged in.
    autoLogin: false,
}

// TODO: This needs to move somewhere else and implemented properly

export const API_ENDPOINT = settings.API_ENDPOINT
export const THEME = settings.THEME
export const FAVICON = settings.FAVICON || `${process.env.PUBLIC_URL}/cpd-favicon.png`
export const TITLE = settings.TITLE || "Provenance | Copyright Delta"

// update favicon and title
document.title = TITLE
const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement
favicon.href = FAVICON
const metaDescription = document.querySelector('meta[name="description"]') as HTMLMetaElement
metaDescription.content = TITLE