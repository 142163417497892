import axios from "axios";
import api from "./base";
import { deleteCreationVersion } from "./creations/deleteCreationVersion";

export interface PresignedUrlResponse {
    creation_id: string;
    creation_version_id: string;
    version: number;
    presigned_url: {
        url: string;
        fields: {
            key: string;
            [headerName: string]: string;
        };
    };
}


export const uploadToS3 = async (presignedData: PresignedUrlResponse, file: File) => {

    const formData = new FormData();

    // Add all fields from the presigned_url object
    for (const [key, value] of Object.entries(presignedData.presigned_url.fields)) {
        formData.append(key, value);
    }

    // formData.append('Content-Type', file.type)
    // Append the file
    formData.append('file', file, file.name);

    // If the url starts with a /, use the ouw own base api client
    let axios_client = axios;
    let url = presignedData.presigned_url.url;
    if (url.startsWith("/")) {
        // remove /api/v1 from the url if it exists
        url = url.replace("/api/v1", "");
        axios_client = api as any;
        formData.append('creation_version_id', presignedData.creation_version_id);
    }

    try {
        const response = await axios_client.post(url, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        // Successfully uploaded
        return response;
    } catch (error) {
        console.error('Error uploading file:', error);
        // Delete creation version if upload fails
        const req = {
            creation_id: presignedData.creation_id,
            version: presignedData.version,
        }
        await deleteCreationVersion(req, true);
        throw error
    }
};
