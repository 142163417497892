import React from 'react';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';


const TableLink = ({ children, href, ...props }) => {
    const theme = useTheme(); // Using the theme to access colors

    // Default style for the link component
    const defaultStyle = {
        '&:hover': { textDecorationColor: theme.palette.text.primary },
    };

    return (
        <Link
            href={href} // Example default prop usage
            underline="hover"
            variant="body2"
            color={theme.palette.text.primary}
            sx={{ ...defaultStyle, ...props.sx }} // Merge custom styles with default styles
            {...props} // Spread the rest of the props to allow overriding
        >
            {children}
        </Link>
    );
};

export default TableLink;