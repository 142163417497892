import { Box, FormControl, FormHelperText, MenuItem, TextField } from '@mui/material'
import { use } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useGetGroups } from '../api/user/getGroups';
import { useTranslation } from 'react-i18next';

const ProfileSelect = () => {
    const { data: groups } = useGetGroups({});
    const [selectedProfile, setSelectedProfile] = useState(localStorage.getItem('selectedProfileId'))

    useEffect(() => {
        const selectedProfileId = localStorage.getItem('selectedProfileId')

        if (!groups || groups.length === 0) {
            // console.log('No groups')
            return
        }

        // Check if selected profile is in the list of profiles
        if (groups && selectedProfileId && !groups.find((profile) => profile.name === selectedProfileId)) {
            // console.log('Selected profile not in list')
            localStorage.setItem('selectedProfileId', groups[0]?.name)
            // hard refresh to home page
            window.location.href = '/'
        }

        if (!selectedProfileId) {
            // console.log('No selected profile')
            localStorage.setItem('selectedProfileId', groups[0]?.name)
            // hard refresh to home page
            window.location.href = '/'
        }
    }, [groups])

    const handleProfileChange = (event) => {
        setSelectedProfile(event.target.value)
        localStorage.setItem('selectedProfileId', event.target.value)
        // hard refresh to home page
        window.location.href = '/'
    }

    const { t } = useTranslation();

    if (groups?.length == 0 || !groups) return null

    return (
        <Box>
            <FormControl>
                <TextField
                    select
                    defaultValue={selectedProfile}
                    id="profile"
                    value={selectedProfile}
                    label={t("select_profile")}
                    onChange={(e) => handleProfileChange(e)}
                >
                    {groups?.map((profile) => (
                        <MenuItem key={profile?.name} value={profile?.name}>
                            {profile?.metadata?.display_name}
                        </MenuItem>
                    ))}
                </TextField>
            </FormControl>
        </Box>
    )
}

export default ProfileSelect
