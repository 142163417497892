import React, { useEffect } from "react";
import FileDisplay from "../components/FileDisplay";
import { Typography, Box, Grid, ThemeProvider } from "@mui/material";
import { useParams } from "react-router-dom";
import { sharingAPI } from "../api/TimestampAPI";
import linkExpired from "../assets/images/linkExpired.png";
import { themes } from "../theme/themes";
import moment from "moment";
import { API_ENDPOINT, THEME } from "../Settings";
import { useTranslation } from "react-i18next";
import LanguageSelect from "../components/LanguageSelect";
import FormatQuoteRoundedIcon from '@mui/icons-material/FormatQuoteRounded';

const ExternalShare = () => {
  var { shareid } = useParams();
  const [url, setUrl] = React.useState("");
  const [name, setName] = React.useState("");
  const [sharedBy, setSharedBy] = React.useState("");
  const [dateShared, setDateShared] = React.useState("");
  const [dateExpires, setDateExpires] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const themeName = THEME

  const theme = themes[themeName];
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      var urldata = await sharingAPI.getSharedFile(shareid);
      // console.log(urldata)
      // let url = urldata.url;
      // if (url.startsWith("/")) {
      //   url = API_ENDPOINT + url;
      // }
      // setUrl(url);
      setUrl(urldata.url);
      setName(urldata.name);
      setSharedBy(urldata.shared_by);
      setDateShared(urldata.date_shared);
      setDateExpires(urldata.date_expires);
      setNotes(urldata.notes);
      console.log(urldata.url)
      if (urldata.url !== undefined && urldata.url !== "") {
        console.log("tracking")
        await sharingAPI.trackSharedFileAccess(shareid);
      }
    })();


    document.documentElement.style.height = '100%';
    document.body.style.height = '100%';
    // Check if theme starts with "rgb" or "#", if so, set background color to white
    if (theme.sharebg.startsWith("rgb") || theme.sharebg.startsWith("#")) {
      document.body.style.backgroundColor = theme.sharebg;
    } else {
      document.body.style.backgroundImage = `url(${theme.sharebg})`;
    }


  }, []);

  const displayFile = (url) => {
    if (url.startsWith("/")) {
      url = API_ENDPOINT + url;
    }
    const mimeType = new URL(url).searchParams.get("content_type");
    const fileExtension = url?.split(".").pop().split("?")[0];
    // check if file extension is in an array of mp3, flac, ogg, wav
    const audioFileExtensions = ["mp3", "flac", "ogg", "wav"];
    const audioFileContentTypes = ["audio/mpeg", "audio/flac", "audio/ogg", "audio/wav"];
    const isAudioFile = audioFileExtensions.includes(fileExtension) || audioFileContentTypes.includes(mimeType);
    const fileDisplay = () => {
      return (
        <FileDisplay
          url={url}
          fileExtention={fileExtension}
          mimeType={mimeType}
          fileName={name}
        />)
    }
    return isAudioFile ? (
      <>
        {fileDisplay()}
      </>

    ) : (
      <Box
        sx={{
          m: "0 auto",
          boxShadow: "0px 5px 20px 0px rgba(0, 0, 0, 0.1)",
          p: { lg: "30px", xs: "10px" },
          pt: { lg: "10px", xs: "5px" },
          borderRadius: "5px",
          backgroundColor: "background.paper",
          mb: 10
        }}
      >
        <Box
          sx={{
            overflow: "auto", height: "100%"
          }}
          className="versionOverflow"
        >
          <Typography variant={"h6"} sx={{ mt: 1, mb: 2 }}>{name}</Typography>
          {fileDisplay()}
        </Box>
      </Box >
    )
  }

  return (
    <ThemeProvider theme={theme?.theme}>
      <Box sx={{
        display: "flex",
        flexDirection: "column",
      }} >
        <Box
          sx={{
            display: "flex",
            backgroundColor: "background.paper",
            height: "60px",
            minHeight: "60px",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <img src={theme?.logo} alt="" style={{ maxHeight: "30px" }} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
        >
          {url ? (
            <>
              <Box sx={{ width: { sm: "90%", lg: "50%" }, maxWidth: "1000px", m: "0 auto" }}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        mt: 3,
                        fontWeight: "600",
                        marginBottom: "10px",
                        fontSize: "16px",
                        color: "white",
                        textAlign: "left",
                      }}
                    >
                      {t('shared_by', { name: sharedBy, date: moment.utc(dateShared).local().format("LLL") })}
                    </Typography>
                    {notes && (<Box sx={{ display: "flex", flexDirection: "row", my: 3, gap: 1 }}>
                      <FormatQuoteRoundedIcon sx={{ color: "white" }} />
                      <Typography
                        variant="body1"
                        color={"white"}
                        textAlign={"left"}
                        sx={{ whiteSpace: "pre-wrap" }}
                      >{notes}</Typography>
                    </Box>)}
                    <Typography variant="body1" sx={{ color: "white", textAlign: "left", mb: 1, fontSize: "14px", }}>
                      {t('link_expires_on', { date: moment.utc(dateExpires).local().format("LLL") })}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "rgba(227, 236, 251, 1)",
                        fontSize: "14px",
                        textAlign: "left",
                        mb: 1
                      }}
                    >
                      {t('timestamped_by', { name: theme?.name })}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
                {displayFile(url)}
              </Box>
            </>
          ) : null}

          {url === undefined && (
            <Box
              sx={{
                backgroundColor: "background.paper",
                p: "20px 40px",
                mt: "20px",
                borderRadius: "10px",
              }}
            >
              <Box component="img" src={linkExpired} />
              <Typography paragraph sx={{ fontWeight: "500px", m: "20px 0px" }}>
                {t('link_expired')}
              </Typography>
            </Box>
          )}

        </Box>
        <Box sx={{ position: "fixed", bottom: "10px", right: "10px", ml: "auto", backgroundColor: "white", borderRadius: 1 }}>
          <LanguageSelect shorthand small />
        </Box>
      </Box>

    </ThemeProvider>
  );
};

export default ExternalShare;
