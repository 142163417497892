import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText } from '@mui/material'
import moment from 'moment';
import React from 'react'
import { useTranslation } from 'react-i18next';

interface SharingLinkDetailDialogProps {
    detailsOpen: boolean;
    handleDetailsClose: () => void;
    selectedShare: any;
}

const SharingLinkDetailDialog: React.FC<SharingLinkDetailDialogProps> = ({ detailsOpen, handleDetailsClose, selectedShare }) => {
    const { t } = useTranslation();
    return (
        <Dialog open={detailsOpen} onClose={handleDetailsClose} fullWidth={true}>
            <DialogTitle>{t("shared_link")}</DialogTitle>
            <DialogContent dividers={true}>
                <List>
                    <ListItem>
                        <ListItemText primary={t("shared_with")} secondary={selectedShare?.shared_email} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={t("date_created")} secondary={moment(selectedShare?.date_created).format("LLL")} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={t("expires on")} secondary={moment(selectedShare?.date_expires).format("LLL")} />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={t("notes")} secondary={selectedShare?.notes} />
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleDetailsClose}>{t("close")}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default SharingLinkDetailDialog
