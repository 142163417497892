import React from "react";
import {
  Box,
  Link,
  Table,
  TableBody,
  TableRow,
  TableContainer,
  Tooltip,
  Alert,
  CircularProgress,
  Typography,
  List,
  ListItem,
  ListItemText,
  useTheme,
} from "@mui/material";
import cpdIcon from "../assets/images/cpdIcon.png";
import avaIcon from "../assets/images/avaIcon.png";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { CellKey, CellValue, CellValueBreakAll } from "./Table/InfoTableCell";

const TimestampingProgressComponent = ({ timestampEntry }) => {
  if (["Pending", "Timestamping"].includes(timestampEntry?.status)) {
    return (<Box sx={{ display: "flex", alignItems: "center", gap: 1, paddingLeft: 2 }}>
      <CircularProgress size={12} />
      <Typography>Timestamping...</Typography>
    </Box>)
  } else if (timestampEntry?.status == "Failed") {
    return <Alert severity="error">There was an error when timestamping this file. Please contact support.</Alert>
  } else {
    return null;
  }
}

const TimestampDisplay = ({ creationVersion, ...props }) => {
  const localTimestamp = creationVersion?.timestamps.find(
    (timestamp) => timestamp.target === "db"
  );
  const publicTimestamp = creationVersion?.timestamps.find(
    (timestamp) => timestamp.target === "avax_mainnet" || timestamp.target === "ava_wagmi_testnet"
  );
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      {creationVersion?.status == "Failed" ? <Alert severity="error" icon={false}>{t('file_processing_error')}</Alert> : null}
      {creationVersion?.status == "Pending" ? <Alert severity="info" icon={false}>{t('file_currently_processing')}</Alert> : null}
      <TableContainer
        component={Box}
        sx={{ pt: 2, maxWidth: "100%" }}
        {...props}
      >
        <List sx={{ display: "flex", flexDirection: "row", p: 0, m: 0 }}>
          <ListItem sx={{ pt: 0, pb: 0, pl: "16px", mt: 0, mb: 0 }}>
            <ListItemText
              primary={t("description")}
              primaryTypographyProps={{ color: theme.palette.text.secondary, fontSize: "14px" }}
              secondaryTypographyProps={{ color: "black", fontSize: "14px", mb: 0, mt: 1 }}
              secondary={creationVersion?.description || t("no_description_available")} />
          </ListItem>
        </List>
        <Table size="small">
          <TableBody>
            <TableRow>
              <CellKey>{t('upload date')}</CellKey>
              <CellValue>{moment(creationVersion?.date_created).format("LLL")}</CellValue>
            </TableRow>
            <TableRow>
              <CellKey>{t('file type')}</CellKey>
              <CellValue>
                {t(creationVersion?.creation.creation_type)}
              </CellValue>
            </TableRow>
            <TableRow>
              <CellKey>{t('uploaded by')}</CellKey>
              <CellValue>
                {creationVersion?.created_by.first_name +
                  " " +
                  creationVersion?.created_by.last_name}
              </CellValue>
            </TableRow>
            <TableRow>
              <CellKey>{t("file hash")}</CellKey>
              <CellValueBreakAll>{creationVersion?.content_hash}</CellValueBreakAll>
            </TableRow>
          </TableBody>
        </Table>

        {localTimestamp && (
          <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, paddingLeft: 2, mt: 2, mb: 1 }}><img src={cpdIcon} height={16} /> Copyright Delta {t("timestamp")}</Box>
            {localTimestamp.timestamp == null ? <TimestampingProgressComponent timestampEntry={localTimestamp} /> :
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <CellKey>{t("timestamp")}</CellKey>
                    <CellValue>{moment(localTimestamp.timestamp).format("LLL")}</CellValue>
                  </TableRow>
                </TableBody>
              </Table>}
          </>
        )}

        {publicTimestamp && (
          <>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1, paddingLeft: 2, mt: 2, mb: 1 }}><img src={avaIcon} height={16} /> Public Network {t("timestamp")}</Box>
            {publicTimestamp.timestamp == null ? <TimestampingProgressComponent timestampEntry={publicTimestamp} /> :
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <CellKey>
                      Transaction ID
                    </CellKey>
                    <CellValueBreakAll>
                      <Tooltip title="View on Blockchain Explorer"><Link href={publicTimestamp.explorer_url} target="_blank" rel="noreferrer" >{publicTimestamp.transaction_id}</Link></Tooltip>
                    </CellValueBreakAll>
                  </TableRow>
                  <TableRow>
                    <CellKey>
                      {t("timestamp")}
                    </CellKey>
                    <CellValue>
                      {moment(publicTimestamp.timestamp).format("LLL")}
                    </CellValue>
                  </TableRow>
                </TableBody>
              </Table>}
          </>
        )}
      </TableContainer>
    </>
  );
};

export default TimestampDisplay;
