import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@mui/material'
import { use } from 'i18next';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { THEME } from '../Settings';
interface AboutDialogProps {
    open: boolean;
    onClose: () => void;
}

const AboutDialog: React.FC<AboutDialogProps> = ({ open, onClose }) => {
    const theme = useTheme();
    const { t } = useTranslation();

    const text = t(`about.${THEME}`)

    const renderText = () => {
        return text.split('\n').map((t, i) => <DialogContentText key={i} sx={{ mb: 3 }}>{t}</DialogContentText>)
    }

    const renderLogo = () => {
        return (
            <img src={theme?.logo} style={{ height: "20px" }} />
        )
    }

    const handleClose = () => {
        localStorage.setItem('seenIntroduction', 'true');
        onClose();
    }
    return (
        <Dialog open={open} onClose={handleClose} fullWidth={true}>
            <Box display={"flex"} alignItems={"center"} sx={{ m: 3 }}>
                {renderLogo()}
            </Box>
            <DialogContent>
                {renderText()}
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AboutDialog
