import api from "../base";
import { useQuery } from "@tanstack/react-query";


type Group = {
    id: string;
    name: string;
    metadata: any;
};

export async function getGroups(): Promise<Group> {
    const response = await api.get(`/user/groups`);
    return response.data;
}

type UseGetGroupsOptions = {
    config?: any;
};

export const useGetGroups = ({ config = {} }: UseGetGroupsOptions) => {
    return useQuery<Array<Group>>({
        ...config,
        queryKey: ["groups"],
        queryFn: () => getGroups(),
    });
};
