import api from "../base";
import { useQuery } from "@tanstack/react-query";

export async function getTenantconfig(): Promise<any> {
    const response = await api.get(`/tenant/config`);
    return response.data;
}

type UseGetTenantConfigOptions = {
    config?: any;
};

export const useGetTenantConfig = ({ config = {} }: UseGetTenantConfigOptions) => {
    return useQuery<any>({
        ...config,
        queryKey: ["tenantConfig"],
        queryFn: () => getTenantconfig(),
    });
};
