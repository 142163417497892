import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { CssBaseline } from "@mui/material";
import { AuthProvider } from "react-oauth2-code-pkce";
import { AUTH_CONFIG } from "./Settings";
// import { CssVarsProvider } from "./theme/myCustomDefaultTheme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient()

root.render(
  <>
    {/* <React.StrictMode> */}
    <CssBaseline />
    <AuthProvider authConfig={AUTH_CONFIG}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </AuthProvider>
    {/* </React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
