import axios from 'axios';
import { API_ENDPOINT, THEME } from "../Settings";

const api = axios.create({
    baseURL: API_ENDPOINT + "/api/v1",
});

// Add an interceptor to set the authentication header
api.interceptors.request.use((config) => {
    // if selectedProfile in local storage, set a header with the selected profile
    const selectedProfile = localStorage.getItem('selectedProfileId');
    if (selectedProfile && selectedProfile !== 'undefined') {
        config.headers['cpd-profile-id'] = selectedProfile;
    }

    const token = localStorage.getItem('ROCP_token').replaceAll("\"", "");
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    config.headers['cpd-tenant-id'] = THEME;
    return config;
});

export default api;
