
import React from 'react';
import { UPLOAD_TYPES } from '../Constants';
import { Icon } from '@mui/material';
import {
  MusicNote as RecordingIcon,
  TextFields as LyricsIcon,
  MusicNote as SheetMusicIcon,
  Movie as VideoIcon,
  Image as ImageIcon,
  Description as AgreementIcon,
  HelpOutline as OtherIcon
} from '@mui/icons-material';
import NotesRoundedIcon from '@mui/icons-material/NotesRounded';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
const CreationTypeIcon = ({ creationType, ...props }) => {
  let IconComponent;

  switch (creationType) {
    case UPLOAD_TYPES.Recording:
      IconComponent = RecordingIcon;
      break;
    case UPLOAD_TYPES.Lyrics:
      IconComponent = NotesRoundedIcon;
      break;
    case UPLOAD_TYPES.SheetMusic:
      IconComponent = SheetMusicIcon;
      break;
    case UPLOAD_TYPES.Video:
      IconComponent = VideoIcon;
      break;
    case UPLOAD_TYPES.Image:
      IconComponent = ImageIcon;
      break;
    case UPLOAD_TYPES.Agreement:
      IconComponent = AgreementIcon;
      break;
    default:
      IconComponent = InsertDriveFileRoundedIcon;
  }

  return <Icon component={IconComponent} {...props} />;
};

export default CreationTypeIcon;
